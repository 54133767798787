<template>
  <b-container fluid>
    <b-row>
      <b-col md="12">
        <iq-card>
          <template v-slot:headerTitle>
            <h4 class="card-title">{{ $t('research_manage.proposal_submission_list') }}</h4>
          </template>
          <template v-slot:body>
            <b-overlay :show="loadingState">
              <b-row>
                <b-col md="12" class="table-responsive">
                  <b-table thead-class="bg-primary" bordered hover :items="listData" :fields="columns" aria-hidden="loading | listReload ? 'true' : null">
                    <template v-slot:cell(index)="data">
                      {{ $n(data.index + 1) }}
                    </template>
                    <template v-slot:cell(created_at)="data">
                        {{ data.item.created_at | dateFormat }}
                    </template>
                    <template v-slot:cell(proposal_status)="data">
                        <span class="badge badge-warning" v-if="data.item.proposal_status == 0">{{ $t('globalTrans.processing') }}</span>
                        <span class="badge badge-danger" v-else-if="data.item.proposal_status == 1">{{ $t('globalTrans.returned') }}</span>
                        <span class="badge badge-danger" v-else-if="data.item.proposal_status == 3">{{ $t('globalTrans.rejected') }}</span>
                        <span class="badge badge-warning" v-else-if="data.item.proposal_status == 4">{{ $t('globalTrans.re_submitted') }}</span>
                        <span class="badge badge-success" v-else>{{ $t('globalTrans.approved') }}</span>
                    </template>
                    <template v-slot:cell(project_id)="data">
                     {{ data.item.project_id > 0 ? ($i18n.locale === 'en' ? data.item.master_project_setup_details.project_name_en : data.item.master_project_setup_details.project_name_bn) : ($i18n.locale === 'en' ? data.item.project_title : data.item.project_title_bn) }}
                    </template>
                    <template v-slot:cell(thematic_area_id)="data">
                    {{ getThematicArea(data.item.thematic_area_id) }}
                    </template>
                    <template v-slot:cell(action)="data">
                        <!-- <b-button v-if="$store.state.Auth.authUser.ssoLogin" style="font-size: 12px !important; margin-top:10px" variant="btn btn-xs btn-success" size="sm" @click="finalSave(data.item)" ><i class="far fa-check-square m-0"></i> {{$t('elearning_venue.forward_to_nothi')}}</b-button> -->
                        <b-button v-if="data.item.proposal_status !== 2 && data.item.proposal_status !== 3 && data.item.proposal_status !== 6" v-b-modal.modal-7 variant="iq-bg-success" class="btn btn-warning mb-1 mr-1" size="sm" title="back" @click="note(data.item)"><i class="ri-arrow-go-back-line"></i></b-button>
                        <b-button v-if="data.item.proposal_status !== 2 && data.item.proposal_status !== 3 && data.item.proposal_status !== 6" v-b-modal.modal-6 variant="iq-bg-success" class="btn btn-success mb-1 mr-1" size="sm" title="Send" @click="note(data.item)">Send TO R.D.</b-button>
                        <b-button v-if="data.item.proposal_status !== 3 && data.item.proposal_status !== 2 && data.item.proposal_status !== 6" v-b-modal.modal-4 variant="iq-bg-danger" class="btn btn-danger mb-1 mr-1" size="sm" title="Reject" @click="note(data.item)"><i class="fa fa-ban m-0" aria-hidden="true"></i></b-button>
                        <b-button title="View Details" v-b-modal.modal-9 variant=" iq-bg-success border-info mb-1" size="sm" @click="view(data.item)"><i class="ri-eye-line m-0 "></i></b-button>
                      <b-button v-if="$store.state.Auth.authUser.ssoLogin && data.item.proposal_status !== 3 && data.item.proposal_status !== 2" :disabled="data.item.proposal_status == 6 ? true : false" style="font-size: 12px !important;" variant="btn btn-xs btn-success" size="sm" @click="finalSave(data.item)" ><i class="fa fa-forward"></i> {{$t('globalTrans.to_nothi')}}</b-button>
                    </template>
                  </b-table>
                </b-col>
              </b-row>
            </b-overlay>
          </template>
        </iq-card>
      </b-col>
    </b-row>
    <b-modal id="modal-4" size="lg" :title="$t('research_manage.review_remark')" :ok-title="$t('globalTrans.close')" ok-only ok-variant="danger">
        <p>
            <RejectV :id="itemId" :key="itemId"/>
        </p>
    </b-modal>
    <b-modal id="modal-6" size="lg" :title="$t('research_manage.review_remark')" :ok-title="$t('globalTrans.close')" ok-only ok-variant="danger">
        <p>
            <ApproveV :id="itemId" :key="itemId"/>
        </p>
    </b-modal>
    <b-modal id="modal-7" size="xs" :title="$t('research_manage.review_remark')" :ok-title="$t('globalTrans.close')" ok-only ok-variant="danger">
        <p>
            <BackV :id="itemId" :key="itemId"/>
        </p>
    </b-modal>
    <b-modal id="modal-9" size="xl" :title="this.$t('external_sidebar.research_proposal_submission_details')" :ok-title="$t('globalTrans.close')" ok-only ok-variant="danger">
      <Details :id="application" :key="application" />
    </b-modal>
  </b-container>
</template>
<script>
import RejectV from './Reject'
import ApproveV from './Approve'
import BackV from './Back'
import RestApi, { agriResearchServiceBaseUrl } from '@/config/api_config'
import { divProposalApprovalPiList, pendingNothiResearchApi } from '../../api/routes'
import Details from './Details'
import ModalBaseMasterList from '@/mixins/modal-base-master-list'
import { mapGetters } from 'vuex'

export default {
  mixins: [ModalBaseMasterList],
  components: {
      RejectV, ApproveV, Details, BackV
  },
  data () {
    return {
      baseUrl: agriResearchServiceBaseUrl,
      testId: 0,
      rows: [],
      itemId: 0,
      application: 0,
      item: ''
    }
  },
  watch: {
    loadingState: function (newVal, oldVal) {
      if (newVal) {
        this.loadData()
      }
    }
  },
  computed: {
    ...mapGetters({
      authUser: 'Auth/authUser'
    }),
    thematicAreaList: function () {
        return this.$store.state.AgriResearch.commonObj.thematicAreaList.filter(item => item.status === 1)
    },
    formTitle () {
       return (this.editItemId === 0) ? this.$t('research_manage.proposal_invitation') + ' ' + this.$t('globalTrans.entry') : this.$t('research_manage.proposal_invitation') + ' ' + this.$t('globalTrans.update')
    },
    currentLocale () {
      return this.$i18n.locale
    },
    columns () {
      const labels = [
          { label: this.$t('globalTrans.sl_no'), class: 'text-left' },
          { label: this.$t('research_manage.thematic_area'), class: 'text-left' },
          { label: this.$t('research_manage.proposal_id'), class: 'text-left' },
          { label: this.$t('research_manage.project_title'), class: 'text-left' },
          { label: this.$t('research_manage.submission_date'), class: 'text-left' },
          { label: this.$t('globalTrans.status'), class: 'text-center' },
          { label: this.$t('globalTrans.action'), class: 'text-center' }
        ]

      let keys = []

      if (this.$i18n.locale === 'bn') {
        keys = [
          { key: 'index' },
          { key: 'thematic_area_id' },
          { key: 'proposal_auto_id' },
          { key: 'project_id' },
          { key: 'created_at' },
          { key: 'proposal_status' },
          { key: 'action' }
        ]
      } else {
        keys = [
          { key: 'index' },
          { key: 'thematic_area_id' },
          { key: 'proposal_auto_id' },
          { key: 'project_id' },
          { key: 'created_at' },
          { key: 'proposal_status' },
          { key: 'action' }
        ]
      }

      return labels.map((item, index) => {
        return Object.assign(item, keys[index])
      })
    }
  },
  mounted () {
    this.loadData()
    // flatpickr('.fromDate', {})
  },
  methods: {
    finalSave (item) {
      const tmpResearchType = this.$store.state.AgriResearch.commonObj.researchTypeList.find(newitem => newitem.value === item.research_type)
      const tmpThematicArea = this.$store.state.AgriResearch.commonObj.thematicAreaList.find(newItem => newItem.value === item.thematic_area_id)
      if (item.research_type === 3) {
        const Org = this.$store.state.orgList.find(itm => itm.value === item.org_id)
        const OfficeType = this.$store.state.commonObj.officeTypeList.find(itm => itm.value === item.office_type_id)
        const Office = this.$store.state.commonObj.officeList.find(itm => itm.value === item.office_id)
        const Division = this.$store.state.AgriResearch.commonObj.divisionalResearchList.find(itm => itm.value === item.divisional_id)
        item.org_name_bn = Org !== undefined ? Org.text_bn : ''
        item.office_type_bn = OfficeType !== undefined ? OfficeType.text_bn : ''
        item.office_name_bn = Office !== undefined ? Office.text_bn : ''
        item.division_name_bn = Division !== undefined ? Division.text_bn : ''
      }

      item.researchTypeName_bn = tmpResearchType !== undefined ? tmpResearchType.text_bn : ''
      item.thematic_area_name_bn = tmpThematicArea !== undefined ? tmpThematicArea.text_bn : ''
      this.toggleStatusCustom2(agriResearchServiceBaseUrl, pendingNothiResearchApi, item)
    },
    toggleStatusCustom2 (baseUrl, uri, item) {
      window.vm.$swal({
        title: window.vm.$t('elearning_venue.are_you_sure_forward_to_nothi'),
        showCancelButton: true,
        confirmButtonText: window.vm.$t('globalTrans.yes'),
        cancelButtonText: window.vm.$t('globalTrans.no'),
        focusConfirm: false
      }).then((result) => {
        if (result.isConfirmed) {
          this.changeStatusCustom(baseUrl, uri, item)
        }
      })
    },
    changeStatusCustom (baseUrl, uri, item) {
      this.$store.dispatch('mutateCommonProperties', { loading: true, listReload: false })
      RestApi.postData(baseUrl, uri, item).then(response => {
        if (response.success) {
          window.vm.$toast.success({
            title: 'Success',
            message: 'Data Updated Successfully',
            color: '#D6E09B'
          })
        } else {
          window.vm.$toast.error({
            title: 'Error',
            message: 'Operation failed! Please, try again.'
          })
        }
        this.$store.dispatch('mutateCommonProperties', { loading: false, listReload: true })
      })
    },
    details (item) {
      this.item = item
    },
    view (item) {
      this.application = item.id
    },
    note (item) {
      this.itemId = item.id
    },
    getThematicArea (id) {
        const objectData = this.$store.state.AgriResearch.commonObj.thematicAreaList.find(type => type.value === parseInt(id))
        if (objectData) {
            if (this.$i18n.locale === 'bn') {
                return objectData.text_bn
            } else {
                return objectData.text_en
            }
        } else {
            return ''
        }
    },
    loadData () {
        this.$store.dispatch('mutateCommonProperties', { loading: true, listReload: false })
        const params = Object.assign({}, { office_id: this.$store.state.Auth.authUser?.office_id > 0 ? this.$store.state.Auth.authUser.office_id : 0, role_id: this.$store.state.Auth.authUser?.role_id > 0 ? this.$store.state.Auth.authUser.role_id : 0 })
        RestApi.getData(agriResearchServiceBaseUrl, divProposalApprovalPiList, params).then(response => {
          if (response.success) {
            this.$store.dispatch('setList', this.dataList(response.data))
            this.paginationData(response.data)
          }
          this.$store.dispatch('mutateCommonProperties', { loading: false, listReload: false })
        })
    },
    dataList (data) {
      const listData = data.map(item => {
        const thematicAreaObj = this.$store.state.AgriResearch.commonObj.thematicAreaList.find(Item => Item.value === item.thematic_area_id)
          const thematicAreaData = {}
          if (typeof thematicAreaObj !== 'undefined') {
            thematicAreaData.thematic_name = thematicAreaObj.text_en
            thematicAreaData.thematic_name_bn = thematicAreaObj.text_bn
          } else {
            thematicAreaData.thematic_name = ''
            thematicAreaData.thematic_name_bn = ''
          }
        return Object.assign({}, item, thematicAreaData)
      })
      return listData
    }
  }
}
</script>
